import React, {lazy, Suspense, useEffect} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./routes/HomePage";
import ArticlePage from "./routes/ArticlePage";
import BuyPage from "./routes/BuyPage";
import ContactPage from "./routes/ContactPage";
import EducatePage from "./routes/EducatePage";
import LeadPage from "./routes/LeadPage";
import BlogPage from "./routes/BlogPage";
import ScrollToTop from "./hoc/ScrollToTop";
import NotFound from "./routes/NotFoundPage";
import {GA_TRACKING_ID, GROWTHBOOK_CLIENT_ID, LANGUAGE} from "./enviroment";
import {GrowthBook, GrowthBookProvider} from "@growthbook/growthbook-react";
import ReactGA from "react-ga4";


ReactGA.initialize(GA_TRACKING_ID);
// const GoogleAnalyticsLazy = lazy(() => import('./utils/GoogleAnalytics'));
const MetaPixelLazy = lazy(() => import('./utils/MetaPixel'));

export const BLOG_LINK = '/blog';
export const BUY_LINK = LANGUAGE === 'sk' ? '/aplikacia' : '/aplikace';
export const CONTACT_LINK = '/kontakt';
export const EDUCATE_LINK = '/ucinky';
export const LEAD_LINK = LANGUAGE === 'sk' ? '/pre-kliniky' : '/pro-kliniky';

const growthbook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: GROWTHBOOK_CLIENT_ID,
    // enableDevMode: process.env.NODE_ENV !== "production",
    trackingCallback: (experiment, result) => {
        setTimeout(() => {
            ReactGA.event('experiment_viewed', {
                experiment_id: experiment.key,
                variation_id: result.variationId
            });
        }, 2000);
    }
});

const LegalModalLazy = lazy(() => import('./components/LegalModal'));

function App() {
    useEffect(() => {
          growthbook.init({ streaming: true }); // Load features asynchronously when the app renders
    }, []);

    return (
        <BrowserRouter>
            <ScrollToTop>
                <Suspense>
                    {/*<GoogleAnalyticsLazy/>*/}
                    <MetaPixelLazy/>
                </Suspense>
                <GrowthBookProvider growthbook={growthbook}>
                    <Routes>
                        <Route index element={<HomePage/>}/>
                        <Route path={BLOG_LINK + "/:slug"} element={<ArticlePage/>}/>
                        <Route path={BLOG_LINK} element={<BlogPage/>}/>
                        <Route path={BUY_LINK} element={<BuyPage/>}/>
                        <Route path={CONTACT_LINK} element={<ContactPage/>}/>
                        <Route path={EDUCATE_LINK} element={<EducatePage/>}/>
                        <Route path={LEAD_LINK} element={<LeadPage/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                    <Suspense>
                        <LegalModalLazy/>
                    </Suspense>
                </GrowthBookProvider>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;
