import {Intl} from "./Intl.d";

const SlovakIntl: Intl = {
    homeHeroHeading: "Inovatívny biomateriál pre regeneratívnu medicínu",
    homeHeroHeading1: "Liečte kĺby stimuláciou prirodzenej tvorby kolagénu",
    homeHeroLead: "Inovatívna liečba bolesti a degeneratívnych zmien kĺbov",
    primaryActionButtonLabel: "Kde na PEPTYS",
    heroSocialProof: "+200 inovatívnych kliník používa PEPTYS",
    homeHeading1: "Liečte bolesť kĺbov stimuláciou prirodzenej tvorby kolagénu",
    homeLead1: "Injekčný roztok s nízkomolekulárnymi peptidmi zlepšuje štruktúru spojivových tkanív a redukuje bolesti do 48 hodín.",
    productGuideHeading: "Ktorý PEPTYS je ten pravý",
    productGuideLead: "Zameraný na rôzne problémy s pohybovým aparátom, PEPTYS je ideálnym riešením pre vaše zdravotné výzvy. Poskytuje účinnú podporu pre kĺby, šľachy, svaly a ďalšie.",
    testimonialsHeading: "Čo hovoria ľudia o PEPTYSe",
    testimonialsLead: "Prečítajte si recenzie od klientov, ktorí využili PEPTYS v boji proti bolestiam a zraneniam. Zistite, ako im tento produkt pomohol s ich problémami.",
    homePageMetaTitle: "Liečba bolesti a degeneratívnych zmien kĺbov",
    homePageMetaDescription: "Účinná liečba kĺbov prostredníctvom stimulácie vlastnej produkcie kolagénu. Zistite ako môzu nízkomolekulárne peptidy pomôcť pri problémoch s kĺbami.",

    linkEducate: "Ako účinkuje",
    linkBlog: "Blog",
    linkContact: "Kontakt",
    linkForDoctors: "Pre kliniky",
    linkApplication: "Kde na PEPTYS",
    linkResearchPapers: "Klinické štúdie",
    linkTerms: "Obchodné podmienky",
    linkPrivacyPolicy: "Ochrana súkromia",
    linkLeaflet: "Návod na použitie",

    benefit1Title: "Prekonajte zranenia",
    benefit1Lead: "Neprestaňte sledovať svoje fitness ciele. PEPTYS poskytuje cielenú podporu pre rýchlejšiu obnovu a znižuje riziko opätovných zranení.",
    benefit2Title: "Zostaňte mladí a silní",
    benefit2Lead: "Čeľte výzvam starnutia a udržte si mladistvú vitalitu. PEPTYS pomáha spomaľovať známky starnutia a zlepšuje celkovú pohodu.",
    benefit3Title: "Obnovte zdravie kĺbov",
    benefit3Lead: "Zmiernite bolesť kĺbov a získajte späť svoju slobodu pohybu. Obnovte pohyb bez obmedzení a vráťte sa k vašim obľúbeným činnostiam.",

    imgAltPain: "Bolesť",
    imgAltTimePast: "Degenerácia",
    imgAltSyringe: "Injekcia",
    imgAltHandHoldingHeart: "Obnova",

    imgAltArrowsDown: "Šípky dole",

    featurePlanHeading1: "Boj s opotrebením a zraneniami",
    featurePlanLead1: "Či už ste športovec bojujúci so športovými zraneniami, alebo osoba cítiaca stopy času, náročná fyzická aktivita môže byť výzvou. Svalové napätie, bolesti kĺbov a znížená mobilita sú bežné prekážky, ktoré ovplyvňujú každodenný život a výkonnosť.",
    featurePlanHeading2: "Porozumenie obmedzeniam tela",
    featurePlanLead2: "Je dôležité rozpoznať, ako zranenia a starnutie ovplyvňujú naše telo. Porozumenie týmto obmedzeniam nám pomáha efektívne ich riešiť. Degenerácia súvisiaca so starnutím a športové zranenia znižujú prirodzenú schopnosť tela opraviť sa a obnoviť.",
    featurePlanHeading3: "Liečivá sila PEPTYS",
    featurePlanLead3: "Tu prichádza PEPTYS - revolučný prístup k prirodzenému hojeniu. Tieto peptidy pracujú na bunkovej úrovni, stimulujúc telo, aby sa opravilo samo. PEPTYS podporuje tvorbu kolagénu, čím napomáha oprave tkanív, znižuje zápal a urýchľuje zotavenie.",
    featurePlanHeading4: "Dosiahnutie úplného zotavenia a vitality",
    featurePlanLead4: "Predstavte si, že znova získate plný rozsah pohybu, silu a vitalitu. S PEPTYS sa to stáva realitou. Užívatelia zažívajú výrazné zlepšenie svojho fyzického stavu, tešia sa z omladeného tela a aktívneho životného štýlu, oslobodeného od bolesti a obmedzenej mobility.",

    learnMoreStripeHeading1: "Ako stručne funguje PEPTYS?",
    learnMoreStripeLead1: "PEPTYS obsahuje LW peptidy, čo sú malé častice bielkovín, ktoré cielene pôsobia na oblasti tela, ktoré potrebujú obnovu. Pri použití tieto peptidy komunikujú vaším bunkám, aby vyrábali viac stavebných materiálov, ako je kolagén, ktorý pomáha pri obnove a regenerácii tkanív. Predstavte si ich ako posilu pre váš prirodzený proces hojenia a obnovy, ktorý vám pomôže získať späť vašu silu a vitalitu.",
    learnMoreStripeButtonLabel1: "Prečítať si viac",
    learnMoreStripeHeading2: "Prečo su LW peptidy lepšie?",
    learnMoreStripeLead2: "Na rozdiel od kolagénových injekcií, ktoré priamo zavádzajú kolagén do tela, LW peptidy fungujú tak, že podporujú prirodzenú produkciu kolagénu v tele. Toto možno považovať za prirodzenejší prístup, keďže využíva a zlepšuje vlastné opravné mechanizmy tela. Malá veľkosť LW peptidov je kľúčová, pretože umožňuje ich ľahkú absorpciu telom, čím zaisťuje efektívne doručenie do cielených oblastí, ako sú tkanivá a kĺby.",
    learnMoreStripeButtonLabel2: "Prečítať si viac",

    productGuidanceTitle1: "Kĺby",
    productGuidanceSyndromes1: ["Osteoartróza", "Meniskus", "Poškodená chrupavka", "Poškodené väzy"],
    productGuidanceTitle2: "Šlachy",
    productGuidanceSyndromes2: ["Skokanské koleno", "Achillová šľacha", "Tenisový lakeť", "Skákavý prst"],
    productGuidanceTitle3: "Chrbtica",
    productGuidanceSyndromes3: ["Bolesti", "Obmedzený pohyb"],
    productGuidanceTitle4: "Malé kĺby",
    productGuidanceSyndromes4: ["Skokanské koleno", "Achillová šľacha", "Tenisový lakeť", "Skákavý prst"],
    productGuidanceTitle5: "Sval",
    productGuidanceSyndromes5: ["Natiahnutý sval", "Natrhnutý sval"],

    testimonialsQuote1: "Dlhodobé bolesti zápästia mi bránili v cvičení. Po ošetrení som pocítil úľavu do 24 hodín. Teraz cvičím bez obmedzení.",
    testimonialsAuthor1: "Maroš Z.",
    testimonialsQuote2: "Trpela som osteoartrózou a meniskusom v kolene, bolesti mi bránili v spánku a závisela som od analgetík. Už po 48 hodinách od ošetrenia som pocítila výraznú úľavu a teraz, pol roka neskôr, som absolvovala preventívnu aplikáciu, aby sa bolesti nevrátili.",
    testimonialsAuthor2: "Božena N.",
    testimonialsQuote3: "S diagnózou pokročilej koxartrózy bedrového kĺbu som čelil bolestiam hlavne v noci a lekár mi odporučil výmenu kĺbu. Ošetrenie mi prineslo pokles intenzity bolesti do 8 dní, a teraz už nepotrebujem analgetiká a môžem sa voľne pohybovať.",
    testimonialsAuthor3: "Jaroslav O.",
    testimonialsQuote4: "Bolesti krčnej chrbtice mi značne komplikovali štúdium. PEPTYS mi priniesol úľavu už do 24 hodín, teraz mám uvoľnenú šiju a môžem sa venovať štúdiu bez bolestí.",
    testimonialsAuthor4: "Natália M.",
    testimonialsQuote5: "PEPTYS som si vybral po sutúre MM menisku a zistenej chondropatii II-III. Aj po 5 mesiacoch od operácie pretrvával výpotok a stuhnutie, no po aplikácii sa stav zlepšil už po 24 hodinách. Po 48 hodinách badateľné zlepšenie hybnosti a aktuálne 4. deň žiadna bolesť ani opuch po troch tréningoch. PEPTYS hodnotím vysoko pozitívne a rozhodol som sa pre aplikáciu aj do druhého kolena.",
    testimonialsAuthor5: "Mrg. Tibor B.",
    testimonialsQuote6: "Aktívne celý život športujem. Zdravý život je moja priorita! Avšak zraneniam sa nedalo vyhnúť. Vyskúšal som PEPTYS a môžem prehlásiť, že časť mojich zdravotných problémov sa s ním dalo vyriešiť. A riešim ich naďalej....",
    testimonialsAuthor6: "Igor I.",
    testimonialsQuote7: "Ako bývalý reprezentant Slovenskej republiky v športovej gymnastike a stále aktívny športovec som začal pociťovať opotrebovanie kĺbov ale po aplikácii PEPTYSu som pocítil úľavu od mojich problémov.",
    testimonialsAuthor7: "Eduard M.",

    agePlural: "rokov",

    nextStepsHeading: "Ďalšie kroky",
    nextStepsForDoctorsHeading: "Pre lekárov",
    nextStepsForDoctorsLead: "Zaujal vás PEPTYS? Zanechajte nám svoje kontaktné údaje a my vás budeme kontaktovať s ponukou na mieru.",
    nextStepsLearnMoreHeading: "Zistiť viac",
    nextStepsLearnMoreLead: "Prečítajte si viac o tom, ako môže PEPTYS pozitívne ovplyvniť vaše zdravie a celkovú pohodu.",
    nextStepsApplicationHeading: "Kde na PEPTYS",
    nextStepsApplicationLead: "Objavte na interaktívnej mape najbližšie miesto, kde si môžete dať vpichnúť PEPTYS.",
    imgAltOpen: "Otvoriť",

    footerText: "PEPTYS je inovatívna zdravotnícka pomôcka účinná pri bolesti a degeneratívnych zmenách kĺbov.",
    footerActionButtonLabel: "Kde na PEPTYS",
    footerCol1Title: "OFFICE",
    footerCol2Title: "ODKAZY",
    footerCol3Title: "ODKAZY",

    articleNotFoundHeading: "Článok nebol nájdený",
    articleBackButton: "Späť na blog",

    blogHeading: "Najnovšie články",
    blogEmptyMessage: "Články sa nepodarilo načítať",
    blogMetaTitle: "Blog",
    blogMetaDescription: "Prečítajte si o inovatívnych riešeniach pre liečbu bolesti a degeneratívnych zmien kĺbov na našom blogu. Odborné články o prípravku PEPTYS, najnovších terapiách a skutočných príbehoch úspechu.",

    imgAltError: "Chyba",

    applicationHeading: "Kde na PEPTYS",
    applicationLead: "Prinášame vám zoznam miest, kde Vám ochotne pichnú peptidovú injekciu PEPTYS. Zavolajte na uvedené telefónne číslo a naši obchodní zástupcovia Vám posunú kontakt na lekára a kliniku, kde injekciu PEPTYS dostanete.",
    mapString: "Mapa",
    applicationPageMetaTitle: "Kde na PEPTYS",
    applicationPageMetaDescription: "Objavte na interaktívnej mape najbližšie miesto, kde si môžete dať vpichnúť PEPTYS.",

    currencySymbol: "€",

    locationString: "Lokácia",
    phoneString: "tel. č.",
    emailString: "e-mail",
    salesRepresentativeString: "Obchodný zástupca",
    salesRepresentativesHeading: "Obchodní zástupcovia",
    salesRepresentativesLead: "Spojte sa s našimi obchodnými zástupcami, ktorí sú pripravení poskytnúť vám podrobné informácie o PEPTYSe a pomôcť vám s obchodnými potrebami.",
    contactOfficeHeading: "Office",
    contactOfficeLead: "Máte otázky alebo potrebujete podporu? Náš tím je tu, aby vám pomohol. Kontaktujte nás pre akékoľvek informácie týkajúce sa PEPTYS, objednávok, alebo spolupráce.",
    contactString: "Kontakt",
    addressString: "Adresa",
    contactPageMetaTitle: "Kontakt",
    contactPageMetaDescription: "Spojte sa s našimi obchodnými zástupcami, ktorí sú pripravení poskytnúť vám podrobné informácie o PEPTYSe a pomôcť vám s obchodnými potrebami.",

    educateFAQ: [
        {
            question: "Čo presne je PEPTYS?",
            answer: "PEPTYS je terapeutický preparát obsahujúci bioaktívne nízkomolekulárne peptidy (LWP), špecificky navrhnutý pre moduláciu regeneratívnych procesov v tkanivách. Tieto peptidy sú optimalizované pre maximálnu biologickú dostupnosť a cieľenú tkanivovú regeneráciu."
        },
        {
            question: "Ako fungujú LW peptidy v PEPTYSe?",
            answer: "LW peptidy v PEPTYSe fungujú ako bioregulátory, aktivujúce endogénne regeneračné mechanizmy tela. Stimulujú syntézu kolagénu a elastínu, čím podporujú obnovu a zdravie spojivových tkanív. Ich malá molekulová hmotnosť zabezpečuje efektívnu penetráciu a biologickú aktivitu na mieste aplikácie."
        },
        {
            question: "Aký je rozdiel medzi LW peptidmi a kolagénom?",
            answer: "Zatiaľ, čo kolagén slúži ako hlavný štrukturálny proteín v tkanivách, LW peptidy zohrávajú rolu signálnych molekúl, ktoré regulujú syntézu kolagénu a ďalších matrixových komponentov. LW peptidy teda neposkytujú priamu štrukturálnu podporu, ale aktivujú intracelulárne signalizačné dráhy, vedúce k anabolickým procesom v tkanivách."
        },
        {
            question: "Čo musím vedieť pred použitím PEPTYSu?",
            answer: "Pred aplikáciou PEPTYSu je nevyhnutné absolvovať klinické vyšetrenie a konzultáciu s odborníkom, aby sa posúdila vhodnosť a bezpečnosť liečby v kontexte konkrétnej klinickej situácie pacienta. Dôkladné porozumenie interakcií s existujúcimi liečebnými režimami a zdravotným stavom pacienta je tiež kľúčové."
        },
        {
            question: "Sú nejaké vedľajšie účinky spojené s používaním PEPTYSu?",
            answer: "PEPTYS je všeobecne dobre tolerovaný, avšak ako pri akejkoľvek biologicky aktívnej látke, môžu nastať lokálne reakcie v mieste aplikácie. Výskyt systémových reakcií je minimálny, ale pri výskyte atypických symptómov sa odporúča okamžitá konzultácia so zdravotníckym odborníkom."
        },
        {
            question: "Ako je zabezpečená kvalita a bezpečnosť PEPTYSu?",
            answer: "Kvalita a bezpečnosť PEPTYSu sú garantované prísnym dodržiavaním farmaceutických a biotechnologických výrobných noriem, vrátane Good Manufacturing Practice (GMP). Výrobok podlieha neustálym kontrolám a validáciám, aby sa zabezpečila jeho konzistentnosť, čistota a biologická efektívnosť."
        },
        {
            question: "Existujú nejaké obmedzenia alebo odporúčania pre dávkovanie PEPTYSu?",
            answer: "Optimálne dávkovanie PEPTYSu je stanovené na základe individuálnej klinickej indikácie a tolerancie pacienta. Lekárska indikácia a monitorovanie reakcie pacienta na terapiu sú kľúčové pre určenie správnej dávky a frekvencie aplikácie. Vždy sa odporúča viesť liečbu pod dohľadom skúseného zdravotníckeho odborníka."
        }
    ],

    educateVideoHeading: "Zistite viac o liečbe pohybového aparátu",
    educateVideoLead: "Injekčný roztok s nízkomolekulárnymi peptidmi stimuluje tvorbu prirodzeného kolagénu a redukuje bolesti do 48 hodín.",

    educateResearchPapersHeading: "Prečítajte si klinické štúdie",
    educateResearchPapersLead: "Prezrite si priebežne aktualizovanú kolekciu klinických štúdií o PEPTYSe.",
    educateResearchPapersButtonLabel: "Klinické štúdie",

    educatePageMetaTitle: "Zdravé kĺby, zníženie bolesti a posilnenie chrupavky vďaka PEPTYSu",
    educatePageMetaDescription: "Zistite, ako PEPTYS prispieva k zdravým kĺbom prostredníctvom zníženia zápalu a bolesti, udržiavania chrupavky a stimulácie tvorby prirodzeného kolagénu.",

    educatePageHeroHeading: "Vďaka čomu PEPTYS spúšta prirodzenú obnovu?",
    educatePageHeroLead: "Prečítajte si ako PEPTYS účinkuje na bunkovej úrovni a čo je za tým vďaka čomu vám môže pomôcť.",
    educatePagePrimaryActionButtonLabel: "Dozvedieť sa viac",

    explanationContainer1Heading: "Začína to znížením zápalu a bolesti",
    explanationContainer1Title1: "Zníženie zápalu a bolesti",
    explanationContainer1Lead1: "LWP v PEPTYS sa zapájajú do zložitých interakcií v rámci bunkových signalizačných sietí. Špecificky sa zameriavajú na zápalové dráhy väzbou na receptory a inhibíciou enzýmov, ktoré zápal spúšťajú. Moduláciou týchto bunkových aktivít pomáhajú LWP obnoviť vyváženú imunitnú odpoveď, ktorá je rozhodujúca pre zníženie chronického zápalu.",
    explanationContainer1Title2: "Inhibícia degradácie tkanív",
    explanationContainer1Lead2: "Lytické enzýmy ako matricové metaloproteinázy (MMP) hrajú kľúčovú úlohu pri degradácii tkaniva. LWP inhibujú nadmernú aktivitu týchto enzýmov, obmedzujú nadmerné odbúravanie zložiek extracelulárnej matrice, čím znižujú zápal a súvisiacu bolesť.",
    explanationContainer1ImgAlt: "Bolesť kolena",

    explanationContainer2Heading: "Pokračuje posilnením a regeneráciou tkanív",
    explanationContainer2Title1: "Stimulácia tvorby kolagénu",
    explanationContainer2Lead1: "LWP stimulujú fibroblasty a chondrocyty na zvýšenie syntézy kolagénu, hlavnej zložky chrupavky a spojivových tkanív. Táto selektívna stimulácia je životne dôležitá pre udržanie integrity tkaniva a uľahčenie procesu hojenia.",
    explanationContainer2Title2: "Podpora regenerácie tkaniva",
    explanationContainer2Lead2: "Reparácia tkaniva zvyčajne vedie k tvorbe jaziev, ktoré sú funkčne horšie ako pôvodné tkanivo. LWP posúvajú reakciu tela smerom k regenerácii, čím podporujú tvorbu tkanív, ktoré sa štruktúrou a funkciou viac podobajú ich pôvodnému stavu.",
    explanationContainer2ImgAlt: "Prirodzená produkcia kolagénu",

    explanationContainer3Heading: "A končí zlepšenou mobilitou",
    explanationContainer3Title1: "Udržiavanie zdravia chrupavky",
    explanationContainer3Lead1: "Chrupavka, tlmiace tkanivo v kĺboch, sa časom opotrebováva. LW peptidy pomáhajú udržiavať zdravie chrupavky tým, že podporujú syntézu jej základných zložiek a zabraňujú ich rozpadu, čím zachovávajú funkčnosť kĺbov.",
    explanationContainer3Title2: "Zvýšená pohyblivosť kĺbov vďaka zdravej chrupavke",
    explanationContainer3Lead2: "Zlepšené zdravie chrupavky sa priamo premieta do zlepšenej pohyblivosti kĺbov. Kĺby sa stávajú pružnejšie a menej bolestivé, čo umožňuje plynulejší pohyb a lepší rozsah pohybu.",
    explanationContainer3ImgAlt: "Sloboda pohybu",

    explanationMAPHeading: "Kľúčová úloha zložky",
    explanationMAPString: "Magnesium Ascorbyl Phosphate (MAP)",
    explanationMAPTitle1: "Biochemická úloha",
    explanationMAPLead1: "Magnesium Ascorbyl Phosphate (MAP) v PEPTYS hrá mnohostrannú úlohu. Podieľa sa na syntéze a stabilizácii peptidov, zatiaľ čo jeho antioxidačné vlastnosti chránia tkanivá pred oxidačným stresom, kľúčovým faktorom starnutia a zápalu.",
    explanationMAPTitle2: "Synergia s LWP",
    explanationMAPLead2: "Začlenenie MAP zvyšuje stabilitu a biologickú dostupnosť LWP, vďaka čomu je kombinácia účinnejšia ako každá zo zložiek samostatne. Táto synergia vedie k lepšiemu terapeutickému účinku, maximalizujúc regeneračný potenciál PEPTYSu.",

    leadPageLeadText: "STE LEKÁR ALEBO KLINIKA?",
    leadPageHeadingText: "Zistite ako môže PEPTYS pomôcť vašim pacientom",
    leadPageStepsTitle: "Čo môžete očakávať?",
    leadPageStep1: "Strategický telefonát, ktorý nám pomôže pochopiť váš prípad",
    leadPageStep2: "Predstavíme jedinečné vlastnosti PEPTYSu",
    leadPageStep3: "Podpora pri integrácii PEPTYSu do vášho terapeutického režimu",
    leadPageThankYouString1: "Ďakujeme za vašu žiadosť.",
    leadPageThankYouString2: "Naši obchodní zástupcovia sa vám ozvú čoskoro.",
    leadPageBackButtonLabel: "Spät na úvodnú stránku",

    leadPageFormName: "Meno a priezvisko",
    leadPageFormEmail: "Pracovný email",
    leadPageFormPhone: "Telefónne číslo",
    leadPageFormClinicName: "Názov kliniky",
    leadPageFormCity: "Mesto",
    leadPageFormTerms1: "Pri odoslaní mojich osobných údajov rozumiem a súhlasím s ich zhromažďovaním a spracovávaním v súlade s",
    leadPageFormTerms2: "podmienkami a pravidlami lunomedic, s.r.o.",
    leadPageFormSubscribe: "Zasielajte mi novinky o PEPTYSe",
    leadPageFormButtonLabel: "Kontaktujte ma",
    leadPageSocialProof: "+200 inovatívnych kliník používa PEPTYS",
    leadPageMetaTitle: "Kontakt pre lekárov a kliniky",
    leadPageMetaDescription: "Otvorte dvere novým možnostiam v liečbe kĺbov. Pripojte sa k PEPTYS a dajte svojim pacientom prístup k najmodernejšej liečbe kĺbov.",

    notFoundPageError: "Chyba 404",
    notFoundPageHeading: "Stránka sa nenašla",
    notFoundPageLead: "Vyzerá to, že stránka, ktorú hľadáte, neexistuje.",
    notFoundPageBackButtonLabel: "Späť",
    notFoundPageHomeButtonLabel: "Domov",
    notFoundPageMetaTitle: "Stránka sa nenašla",

    errorString: "Chyba",

    companyString: "Spoločnosť"

}

export default SlovakIntl;